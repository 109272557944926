import jQuery from 'jquery/dist/jquery';
import Utils from '../../../../../utils'
import DefaultController from "../../../../defaultController";
export default class ErpStockProductList {
    tableElem: any;
    datatable: any;
    private parent: any;
    private datatableData: any;

    constructor(parent: any) {
        this.parent = parent
        this.init()
        this.bindListeners();
    }

    init() {
        this.tableElem = jQuery('.datatables-users')
        this.createTable();
    }
    bindListeners() {
        (document.querySelector("body") as HTMLElement).addEventListener("click", async (e: any) => {
            const target = e.target as HTMLElement;
            if(target && target.classList.contains("download-btn")) {
                let csv = '';
                csv += Object.values(this.datatable.columns().header().map((h: { innerHTML: any; }) => h.innerHTML)).slice(0,this.datatable.columns()[0].length).join(";")+"\n";
                this.datatableData.forEach((entry: any) => {

                    let content = [];
                    content = [
                        entry.productNumber,
                        entry.name,
                        this.generateLocation(entry).join(", "),
                        this.generateStock(entry),
                        this.generateStockReserved(entry),
                        this.generateStockAvailable(entry),
                    ]
                    csv += content.join(";")+"\n";
                })

                await Utils.download(new Blob(["\ufeff", csv]), 'export.csv')
            }
        });
    }



    formatNumber(num: string) {
        return (Math.round(parseFloat(num) * 100) / 100).toFixed(2);
    }

    generateLocation(item: any) {
        let locations: string[] = [];
        item.extensions[0].warehouseLocationProductMappings.forEach((mapping: any) => {
            locations.push(mapping.warehouseLocation[0].warehouse[0].shortcode+"|"+mapping.warehouseLocation[0].name);
        })
        return locations
    }

    generatePriceNet(item: any) {
        return this.formatNumber(item.price[0].net);
    }

    generatePriceAll(item: any) {
        return this.formatNumber(item.price[0].gross);
    }

    generateStock(item: any) {
        return item.extensions[0].warehouseLocationProductMappings.map((m: { stock: any; }) => m.stock).reduce((pv: any, cv: any) => pv + cv, 0);
    }

    generateStockReserved(item: any) {
        return item.extensions[0].warehouseLocationProductMappings.map((m: { stockReserved: any; }) => m.stockReserved && m.stockReserved.length > 0 ? m.stockReserved : 0).reduce((pv: any, cv: any) => pv + cv, 0);
    }

    generateStockAvailable(item: any) {
        return this.generateStock(item) - this.generateStockReserved(item);
    }

    createTable() {
        this.datatable = this.tableElem.DataTable({
            initComplete: () => {
                this.tableElem.closest(".card").find(".loading-body").addClass("d-none")
                this.tableElem.closest(".card").find(".card-datatable").removeClass("d-none")
            },
            "ajax": {
                "type" : "GET",
                "url" : `/api/v1/erp/exports`,
                "dataSrc":  ( json: any ) => {
                    this.datatableData = json.data;
                    return json.data;
                }
            },
            columns: [
                {data: 'productNumber'},
                {data: 'name'},
                {data: 'warehouseLocations'},
                {data: 'stock'},
                {data: 'stockReserved'},
                {data: 'stockAvailable'},
            ],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return this.generateLocation(full).join(", ");
                    },
                },
                {
                    targets: 3,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return this.generateStock(full);
                    },
                },
                {
                    targets: 4,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return this.generateStockReserved(full);
                    },
                },
                {
                    targets: 5,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return this.generateStockAvailable(full);
                    },
                }
            ],
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-6 mt-3"p>' +
                '>',
            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `${Utils.translate('generic.download')}`,
                    className: 'dt-button btn btn-primary m-2 download-btn'
                }
            ]
        });
    }
}